<template>
  <div>
    <h1>404 NOT FOUND</h1>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch("setLoading", false);
  },
};
</script>
